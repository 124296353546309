import s from './IndexPage.module.css';
import {useEffect, useRef} from "react";

export default function IndexPage(props) {
    const bottomShadowRef = useRef(null);
    const imageRef = useRef(null);
    const infoRef = useRef(null);
    const mediaRef = useRef(null);
    const mediaInfoRef = useRef(null);

    useEffect(() => {
        console.log(imageRef.current + ' is ' + window.$(imageRef.current) + ' animated')

        window.$(bottomShadowRef.current).animate({ opacity: 1 }, 1100, () => {});
        window.$(imageRef.current).animate({ opacity: 1, marginTop: '1.4em' }, 1800, function() {
            console.log(imageRef.current + ' is ' + window.$(imageRef.current) + ' animated')

            window.$("#top-right-top").animate({ width: '8em' }, 1100, () => {});
            window.$("#bottom-left-top").animate({ width: '4em' }, 1100, () => {});

            window.$(infoRef.current).animate({ opacity: 1 }, 1100, () => {});
            window.$(mediaRef.current).animate({ opacity: 1 }, 1100, () => {});

            setTimeout(() => {
                window.$("#top-right-bottom").animate({ width: '4em' }, 1100, () => {});
                window.$("#bottom-left-bottom").animate({ width: '8em' }, 1100, () => {});
                window.$(imageRef.current).animate({ filter: 'brightness(1.5)' }, 500, function() {});
            }, 150);
        });

        window.$(mediaRef.current).hover(() => {
            window.$(mediaInfoRef.current).animate({ opacity: 1 }, 300, () => {});
        }, () => {
            window.$(mediaInfoRef.current).animate({ opacity: 0 }, 200, () => {});
        });
    }, []);


    return (
        <>
            <div className={s.content}>
                <div className={s.image} ref={imageRef}>
                    <div className={s.lineBlock + ' ' + s.topRight}>
                        <div className={s.line} id="top-right-top"></div>
                        <div className={s.line + ' ' + s.bottomRight} id="top-right-bottom"></div>
                    </div>
                    <div className={s.lineBlock + ' ' + s.bottomLeft}>
                        <div className={s.line} id="bottom-left-top"></div>
                        <div className={s.line + ' ' + s.bottomLeft} id="bottom-left-bottom"></div>
                    </div>

                    <div className={s.media} ref={mediaRef}>
                        <div className={s.mediaInfo} ref={mediaInfoRef}>
                            <h3><a href="#">Пока не решила,</a></h3>
                            <p>какой трек поставить</p>
                        </div>
                        <div className={s.thumbnail}>
                        </div>
                    </div>

                    <div className={s.info} ref={infoRef}>
                        <h1 className={s.title}>Новый лэндинг?</h1>
                        <p className={s.description}>
                            Это лэндинг, который я написала еще
                            <br/>
                            года два назад. Разница лишь в том, что
                            <br/>
                            я поменяла изображения, цветовую палитру,
                            <br/>
                            а также переписала его на React, поскольку
                            <br/>
                            хочу сделать все прям шикарно.
                            <br/>
                            Пока что пусть лэндинг повисит так.
                        </p>
                    </div>
                </div>
            </div>
            <div className={s.bottomShadow} ref={bottomShadowRef}>
            </div>
        </>
    );
}