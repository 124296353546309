import {BrowserRouter, Route, Routes} from "react-router-dom";
import Layout from "./pages/Layout/Layout";
import IndexPage from "./pages/Index/IndexPage";

export default function App() {
    return <>
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Layout/>}>
                    <Route index element={<IndexPage/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    </>;
}
